import React, { useState, useEffect, useReducer, CSSProperties, useRef, Suspense } from 'react';
import {
    MenuItem,
    TextField,
    Theme,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    Box
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import qs from 'querystring';
import DOMPurify from 'dompurify';

// Sortable
// import { SortableContainer, SortableElement } from 'react-sortable-hoc';

// Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import TablePaginationActions from './TablePaginationActions';

// Utilities
import SortableTableHeader from './SortableTableHeader';

// Component
import LoadingScreen from '../LoadingScreen';
import MiniButtonFilter from '../MiniButtonFilter';

// Type
import { Order } from './SortableTableHeader';

//Utils
import { numberToCurrency } from '../../_utils/Helper';

// Datepicker

// Styles
import './CSS/datatable.css';
import DateRangePicker from '../DateRangePicker';
import { format } from 'date-fns';
import { generateStyle } from '../../_utils/DefaultStyle';

const ListSubheader = React.lazy(() => import('@mui/material').then(module => ({ default: module.ListSubheader })))
const TablePagination = React.lazy(() => import('@mui/material').then(module => ({ default: module.TablePagination })))

interface Props {
    url: string;
    columns: IColumn[];
    reDraw?: number;
    orders?: [
        string,
        Order
    ];
    onSort?: Function;
    sorting?: boolean;
    customParams?: Object;
    customParamsFromFirstPage?: {
        [key: string]: any
    }
    tab?: React.ReactNode;
    rowCallback?: Function;
    responseCallback?: Function;
    seenId?: any;
    closing?: boolean;
    setParentQueryString?: (queryString: string) => void
    extraFooterRow?: (IColumnNew | null)[]
    disableFetch?: boolean
    renderExtraFooter?: (data: any[]) => React.ReactNode
    rowsPerPage?: number
    processParamsBeforeSendingToAPI?: (params: { [key: string]: any }) => { [key: string]: any }
    filterButtons?: {
        key: string
        defaultValue: string | null
        options: {
            value: null | string
            label: string
        }[]
    }[]
    ListUnitEntrustedKey?: boolean
}

export interface IColumn {
    filterable?: boolean;
    sortable?: boolean
    name: string;
    label: string;
    type?: string;
    render?: Function;
    width?: string;
    style?: CSSProperties;
    columnStyle?: CSSProperties | ((row: any) => CSSProperties);
    options?: any[] | Object;
    defaultOption?: string | number;
    onOptionChange?: (value: string, setOptionQuery: (value: any) => void) => void
}

export interface IColumnNew extends IColumn {
    render?: (items: { [key: string]: any }[]) => React.ReactNode
}

type FilterButtonQuery = { [key: string]: string | null }

let typingTimer: any; //timer identifier
const useStyles = generateStyle((theme: Theme) => ({
    table: {
        // position: 'relative'
    },
    tableContainer: {
        position: 'relative',
        maxHeight: "70vh"
    },
    tableHeadSticky: {
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
    },
    secondHeader: {
        '& .MuiTableCell-stickyHeader': {
            position: 'relative'
            // top: 80,
        },
    },
    noData: {
        textAlign: 'center'
    },
    loadingBody: {
        textAlign: 'center',
        position: 'relative',
        height: '100px'
    },
    pagination: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        height: '52px'
    },
    paginationInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    toggleSeeTotal: {
        marginLeft: '16px'
    }
}), "DataTable")

export default function DataTable(props: Props) {
    const { Root, classes } = useStyles();
    const columns = props.columns;
    const location = useLocation();
    const tmpCustomParams = useRef<any>({});

    const [rows, setRows] = useState<any[]>([]);
    const [firstInit, setFirstInit] = useState(true);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [hasMore, setHasMore] = React.useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage || 10);
    const [order, setOrder] = React.useState<Order>(props.orders && props.orders[1] ? props.orders[1] : false);
    const [orderBy, setOrderBy] = React.useState<string>(props.orders && props.orders.length > 0 ? props.orders[0] : '');
    const [loading, setLoading] = React.useState(true);
    const [initialLoad, setInitialLoad] = useState(false)
    const [totalGMV, setTotalGMV] = useState(0)
    const [totalComission, setTotalComission] = useState(0)

    const paramsReducer = (state: any, action: any) => {
        switch (action.type) {
            case 'SET_ITEM':
                return {
                    ...state,
                    [action.name]: action.value
                }
            case 'REPLACE_STATE':
                const newState = action.value;
                return { ...state, ...newState };
            default:
                break;
        }

        return { ...state };
    }

    // Params
    const [queries, setQuery] = useReducer(paramsReducer, {});
    const [optionQueries, setOptionQuery] = useReducer(paramsReducer, {});
    const [dateQueries, setDateQuery] = useReducer(paramsReducer, {});
    const [monthQueries, setMonthQueries] = useReducer(paramsReducer, {});
    const [buttonFilterQuery, setButtonFilterQuery] = useState<FilterButtonQuery>({})
    const [seeTotal, setSeeTotal] = useState(false)

    useEffect(() => {
        let initialParams: any = {};
        let initialOptionParams: any = {};
        let initialDateParams: any = {};
        let initialDateFocus: any = {};
        let initialMonthParams: any = {};
        let initialFilterButtonQuery: FilterButtonQuery = {}
        // let initialMonthFocus: any = {};

        props.columns.forEach((column: any, index) => {
            if (typeof column.options !== 'undefined' && typeof column.filterable !== 'undefined' && column.filterable) {
                initialOptionParams[column.name] = column.defaultOption && column.defaultOption !== '' ? column.defaultOption : '';
            } else if (typeof column.type !== 'undefined' && column.type === 'date' && typeof column.filterable !== 'undefined' && column.filterable) {
                if (column.defaultOption === '' || column.defaultOption === undefined) {
                    initialDateParams[`${column.name}_start`] = '';
                    initialDateParams[`${column.name}_end`] = '';
                } else {
                    const defaultOptionSplitted = column.defaultOption.split(' - ');
                    initialDateParams[`${column.name}_start`] = defaultOptionSplitted[0] ? new Date(defaultOptionSplitted[0]) : '';
                    initialDateParams[`${column.name}_end`] = defaultOptionSplitted[1] ? new Date(defaultOptionSplitted[1]) : '';
                }
                initialDateFocus[column.name] = null;
            } else if (typeof column.type !== 'undefined' && column.type === 'month' && typeof column.filterable !== 'undefined' && column.filterable) {
                initialMonthParams[column.name] = '';
                // initialMonthFocus[column.name] = null;
            } else if (typeof column.filterable !== 'undefined' && column.filterable) {
                initialParams[column.name] = "";
            }
        });

        // Initialize filter buttons query
        if (props.filterButtons && props.filterButtons.length > 0) {
            props.filterButtons.forEach(filter => {
                initialFilterButtonQuery[filter.key] = filter.defaultValue
            })
        }

        const queryParams = new URLSearchParams(location.search);

        queryParams.forEach((value: string, key: string) => {
            const column = props.columns.find(item => {
                return item.name === key;
            });

            if (column) {
                if (column.type === 'string' || column.type === 'number') {
                    initialParams[key] = DOMPurify.sanitize(value);
                }

                if (typeof column.options !== 'undefined') {
                    initialOptionParams[key] = DOMPurify.sanitize(value);
                }

                if (column.type === 'date') {
                    const exploded = value.split(' - ');
                    let start = DOMPurify.sanitize(exploded[0])
                    let end = DOMPurify.sanitize(exploded[1])
                    initialDateParams[`${key}_start`] = start ? new Date(start) : '';
                    initialDateParams[`${key}_end`] = end ? new Date(end) : '';
                }
            } else {
                // Check for filter button param
                if (props.filterButtons?.find(filter => filter.key === key) && value) {
                    initialFilterButtonQuery[key] = DOMPurify.sanitize(value)
                }
            }
        });

        setQuery({ name: '', value: initialParams, type: 'REPLACE_STATE' });
        setOptionQuery({ name: '', value: initialOptionParams, type: 'REPLACE_STATE' });
        setDateQuery({ name: '', value: initialDateParams, type: 'REPLACE_STATE' });
        setMonthQueries({ name: '', value: initialMonthParams, type: 'REPLACE_STATE' });
        setButtonFilterQuery(initialFilterButtonQuery)
        setInitialLoad(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (initialLoad && firstInit && !props.disableFetch) {
            loadTable()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialLoad, props.disableFetch])

    useEffect(() => {
        if (!firstInit) {
            loadTable();
        }
        // eslint-disable-next-line
    }, [page, rowsPerPage, order, orderBy, props.reDraw, optionQueries, seeTotal, buttonFilterQuery]);

    useEffect(() => {
        if (!firstInit) {
            if (page === 0) {
                loadTable()
            }
            else {
                setPage(0)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.customParamsFromFirstPage])

    useEffect(() => {
        if (!firstInit) {
            let doneTypingInterval = 1500;
            clearTimeout(typingTimer);
            const doneTyping = () => loadTable()
            typingTimer = setTimeout(doneTyping, doneTypingInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.url, queries]);

    useEffect(() => {
        if (typeof props.customParams !== 'undefined') {
            let reload = false;

            // console.log(tmpCustomParams.current, props.customParams);
            for (let [k, v] of Object.entries(props.customParams)) {
                if (typeof tmpCustomParams.current[k] === 'undefined') {
                    reload = true;
                } else if (tmpCustomParams.current[k] !== v) {
                    reload = true;
                }
            }

            tmpCustomParams.current = { ...props.customParams };
            if (reload && !firstInit) {
                loadTable();
            }
        }
        // eslint-disable-next-line
    }, [props.customParams]);

    const handleButtonFilterChange = (name: string, value: string | null) => {
        setButtonFilterQuery(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const loadTable = () => {
        let newDateQueries: any = {};
        let newMonthQueries: any = {};

        for (let i in monthQueries) {
            newMonthQueries[i] = monthQueries[i] ? format(new Date(monthQueries[i]), 'yyyy-MM-dd') : '';
        }

        for (let i in dateQueries) {
            let key = String(i).replace(/(_start|_end)$/gi, '');
            if (typeof newDateQueries[key] === 'undefined') {
                newDateQueries[key] = dateQueries[i] ? format(new Date(dateQueries[i]), 'dd/MM/yyyy') : '';
            } else {
                newDateQueries[key] += ' - ' + (dateQueries[i] ? format(new Date(dateQueries[i]), 'dd/MM/yyyy') : '');
            }
        }

        pushQueryParams();
        setLoading(true);

        let params = { ...queries, ...optionQueries, ...newMonthQueries, ...newDateQueries, ...buttonFilterQuery, ...props.customParams, ...props.customParamsFromFirstPage, page: page + 1, rowsPerPage, order: orderBy, dir: order, see_total_data: seeTotal ? 1 : 0 }
        if (props.processParamsBeforeSendingToAPI) {
            params = props.processParamsBeforeSendingToAPI(params)
        }

        import('../../_utils/DefaultAxios')
            .then(module => module.default)
            .then(DefaultAxios => DefaultAxios.get(props.url, { params: params }))
            .then(res => {
                setRows(res.data.data);
                setHasMore(res.data.more);
                setCount(res.data.total || 0);
                if (props.closing) {
                    const resultGMV = [...res.data.data].reduce((total, currentValue) => currentValue.status === 0 || currentValue.status === 3 ? total : total + currentValue.gmv_transaction, 0)
                    const resultComission = [...res.data.data].reduce((total, currentValue) => currentValue.status === 0 || currentValue.status === 3 ? total : total + currentValue.revenue_jendela, 0)
                    setTotalGMV(resultGMV)
                    setTotalComission(resultComission)
                }
                if (typeof props.responseCallback !== 'undefined') {
                    props.responseCallback(res.data, seeTotal);
                }
            })
            .finally(() => {
                if (firstInit) {
                    setFirstInit(false);
                }
                setLoading(false);
            });
    }

    const pushQueryParams = () => {
        const filteredQueries: any = {};
        for (let key in queries) {
            if (queries[key]) {
                filteredQueries[key] = queries[key];
            }
        }

        for (let key in buttonFilterQuery) {
            if (buttonFilterQuery[key]) {
                filteredQueries[key] = buttonFilterQuery[key];
            }
        }

        for (let key in optionQueries) {
            if (optionQueries[key]) {
                filteredQueries[key] = optionQueries[key];
            } else if (filteredQueries[key] && queries[key]) {
                delete filteredQueries[key]
            }
        }

        for (let i in dateQueries) {
            let key = String(i).replace(/(_start|_end)$/gi, '');
            if (typeof filteredQueries[key] === 'undefined') {
                filteredQueries[key] = dateQueries[i] ? format(new Date(dateQueries[i]), 'yyyy-MM-dd') : '';
            } else {
                filteredQueries[key] += ' - ' + (dateQueries[i] ? format(new Date(dateQueries[i]), 'yyyy-MM-dd') : '');
            }

            if (String(filteredQueries[key]).trim() === '-') {
                delete filteredQueries[key];
            }
        }

        if (props.customParamsFromFirstPage) {
            for (let key in props.customParamsFromFirstPage) {

                if (props.customParamsFromFirstPage[key]) {
                    filteredQueries[key] = props.customParamsFromFirstPage[key]
                }

            }
        }

        const queryString = Object.keys(filteredQueries).length ? '?' + qs.stringify(filteredQueries) : ''
        window.history.pushState([], 'List', queryString || location.pathname);
        if (props.setParentQueryString) {
            props.setParentQueryString(queryString.replace('?', ''))
        }
    }

    const filterChange = (e: any) => {
        setQuery({ type: 'SET_ITEM', name: e.target.name, value: e.target.value });
        setPage(0);
    }

    const optionChange = (e: any, column: IColumn) => {
        const { name, value } = e.target

        setOptionQuery({ type: 'SET_ITEM', name, value });
        setPage(0);

        if (column.onOptionChange) {
            column.onOptionChange(value, setOptionQuery)
        }
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isAsc ? 'desc' : (isDesc ? false : 'asc'));
        setOrderBy(!isDesc ? property : '');
    };

    const handleDateChanged = (dates: any, columnName: string) => {
        let newState = {
            [`${columnName}_start`]: dates.startDate,
            [`${columnName}_end`]: dates.endDate
        }
        setDateQuery({ type: 'REPLACE_STATE', name: '', value: newState });
    }

    const getTableBody = () => {
        if (loading) {
            return (
                <TableRow>
                    <TableCell
                        className={classes.loadingBody}
                        colSpan={props.columns.length}
                    >
                        <LoadingScreen open={loading} fullScreen={false} />
                    </TableCell>
                </TableRow>
            );
        } else if (rows.length > 0) {
            return (
                rows.map((row, i: number) => {
                    let index = i;
                    const tableRow = columns.map((column: any, i: number) => {
                        let value = null;
                        let style = {};
                        if (typeof row[column.name] !== 'undefined') {
                            if (column.render && typeof column.render === 'function') {
                                if (column.options && column.options !== '') {
                                    value = column.render(row[column.name], row, column.name, column.options, setRows, rows);
                                } else {
                                    value = column.render(row[column.name], row, rows, index, setRows);
                                }
                            } else {
                                value = row[column.name];
                            }
                        } else if (column.name === 'EXTRA' && column.render && typeof column.render === 'function') {
                            value = column.render(row);
                        }

                        if (typeof props.rowCallback !== 'undefined') {
                            style = { ...style, ...(props.rowCallback(row) ?? {}) };
                        }

                        if (typeof column.columnStyle !== 'undefined') {
                            if (typeof column.columnStyle === 'function') {
                                style = { ...style, ...(column.columnStyle(row) ?? {}) };
                            } else {
                                style = { ...style, ...(column.columnStyle ?? {}) };
                            }
                        }

                        return <TableCell key={i} style={style}>{value}</TableCell>
                    });

                    return (
                        <TableRow style={{ backgroundColor: props.seenId ? (props.seenId.includes(row.id) && '#e8e8e8') : '' }} key={i}>
                            {tableRow}
                        </TableRow>
                    )
                })
            )
        } else {
            return (
                <TableRow>
                    <TableCell
                        className={classes.noData}
                        colSpan={props.columns.length}
                    >
                        No Data Available
                    </TableCell>
                </TableRow>
            )
        }
    }

    const renderFilter = (column: any) => {
        if (column.filterable) {
            if (column.type === 'date') {
                return (
                    <Suspense fallback={<SuspenseFallback />}>
                        <DateRangePicker
                            startDate={dateQueries[`${column.name}_start`] ? dateQueries[`${column.name}_start`] : null}
                            endDate={dateQueries[`${column.name}_end`] ? dateQueries[`${column.name}_end`] : null}
                            onDatesChange={e => handleDateChanged(e, column.name)}
                            onFinished={() => {
                                // Reset the page if filtered from page other than page 1
                                if (page === 0) {
                                    loadTable()
                                } else {
                                    setPage(0)
                                }
                            }}
                            dateMaxWidth={50}
                        />
                    </Suspense>
                );
            } else if (typeof column.options !== 'undefined') {
                const options = column.options.map((item: any, key: any) => {
                    if (item.type === 'parent') {
                        return (
                            <Suspense key={key} fallback={<SuspenseFallback />}>
                                <ListSubheader key={key} style={{ pointerEvents: 'none', fontWeight: 700 }}>{item.value}</ListSubheader>
                            </Suspense>
                        )
                    } else {
                        return (
                            <MenuItem key={key} value={item.key} style={item.style && item.style}>{item.value}</MenuItem>
                        );
                    }
                })
                return (
                    <TextField
                        select
                        variant="outlined"
                        onChange={e => optionChange(e, column)}
                        name={column.name}
                        value={optionQueries[column.name] !== '' ? optionQueries[column.name] : column.defaultOption}
                        size="small"
                        style={{ width: "100%" }}
                        defaultValue=""
                    >
                        {options}
                    </TextField>
                );
            } else {
                return (
                    <TextField
                        name={column.name}
                        value={queries[column.name]}
                        variant="outlined"
                        size="small"
                        onChange={filterChange}
                        placeholder={column.type === 'number' ? 'Use >,<,=,&' : ''}
                        InputProps={column.width ? {
                            style: {
                                width: `${column.width}px`
                            }
                        } : {}}
                    />
                )
            }
        } else {
            return (
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.12)" }}
                    InputProps={{
                        style: { width: "100%" }
                    }}
                />
            )
        }
    }

    return (
        <Root>
            {
                (props.filterButtons && props.filterButtons.length > 0) ?
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: props.ListUnitEntrustedKey ? 3 : 2, }} >
                        {
                            props.filterButtons.map(filter =>
                                <Box
                                    key={filter.key}
                                    sx={{
                                        mr: 1,
                                    }}
                                >
                                    <MiniButtonFilter
                                        items={filter.options}
                                        value={buttonFilterQuery[filter.key]}
                                        onChange={newValue => handleButtonFilterChange(filter.key, newValue)}
                                        containerStyle={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                        }}
                                    />
                                </Box>
                            )
                        }
                    </Box>
                    : null
            }
            <Paper>
                <TableContainer className={classes.tableContainer}>
                    {
                        props.tab ? props.tab : null
                    }
                    <Table stickyHeader className={classes.table} size="small" aria-label="a dense table">
                        <TableHead className={classes.tableHeadSticky}>
                            <SortableTableHeader
                                sorting={typeof props.sorting !== 'undefined' ? props.sorting : true}
                                headCells={props.columns.slice()}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            {
                                (Object.keys(queries).length || Object.keys(optionQueries).length || Object.keys(dateQueries).length) ?
                                    <TableRow>
                                        {props.columns.map((column: any, i: number) => {
                                            return (
                                                <TableCell key={i} style={column.style}>
                                                    {renderFilter(column)}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                    : null
                            }
                        </TableHead>
                        <TableBody>
                            {getTableBody()}
                        </TableBody>
                        {
                            props.extraFooterRow ?
                                <TableBody
                                    sx={{
                                        position: 'sticky',
                                        bottom: 0,
                                        left: 0,
                                    }}
                                >
                                    <TableRow>
                                        {
                                            props.extraFooterRow.map((column, index) =>
                                                <TableCell
                                                    key={index}
                                                    style={column?.style}
                                                    sx={{
                                                        backgroundColor: 'white',
                                                        borderTop: '1px solid rgba(224, 224, 224, 1)',
                                                    }}
                                                >
                                                    {column?.render ? column.render(rows) : ''}
                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableBody>
                                : null
                        }

                    </Table>
                </TableContainer>
                {
                    props.closing &&
                    <div style={{ textAlign: 'end', padding: '8px', fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.87)' }}>
                        <div>Total GMV: {numberToCurrency(totalGMV, 'Rp')}</div>
                        <div>Total Komisi: {numberToCurrency(totalComission, 'Rp')}</div>
                    </div>
                }
                {
                    props.renderExtraFooter ?
                        <div style={{ textAlign: 'end', padding: '8px', fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.87)' }}>
                            {props.renderExtraFooter(rows)}
                        </div>
                        : null
                }
                <div>
                    <Suspense fallback={<SuspenseFallback />}>
                        <div className={classes.pagination} style={{ position: 'relative' }}>
                            <div className={classes.toggleSeeTotal}>
                                <FormGroup>
                                    <FormControlLabel
                                        value={seeTotal}
                                        onChange={(_, checked) => setSeeTotal(checked)}
                                        control={<Checkbox size='small' />}
                                        label={<Typography variant="body2" color="textSecondary">Show Total Data</Typography>}
                                    />
                                </FormGroup>
                            </div>
                            <div
                                style={{ position: 'absolute', right: 0, top: 0, maxWidth: '60%' }}
                            >
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TablePagination
                                                // className={classes.pagination}
                                                rowsPerPageOptions={[5, 10, 25, 100, 500]}
                                                count={count ? count : 99999}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                showFirstButton={true}
                                                showLastButton={seeTotal}
                                                nextIconButtonProps={{
                                                    disabled: !hasMore
                                                }}
                                                labelDisplayedRows={(paginationInfo) => (
                                                    <span>{`${paginationInfo.from} - ${paginationInfo.to}${seeTotal ? ` of ${count}` : ''}`}</span>
                                                )}

                                            />
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </Suspense>
                </div>
            </Paper>
        </Root>
    );
}

const SuspenseFallback = () => (
    <div>Loading...</div>
)