import React, { useReducer, useState, useEffect, useRef } from 'react';

/**
 * Components
*/
import { Grid, Theme, TextField, FormControlLabel, Button, FormGroup, Switch, Checkbox, Typography, Collapse, InputAdornment, MenuItem } from '@mui/material';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../_components/LoadingScreen';
import ButtonRadioND2 from '../../_components/_formElements/_newDesign2/ButtonRadioND2';
import TextFieldND2 from '../../_components/_formElements/_newDesign2/TextFieldND2';
import OptionSwitcherND2 from '../../_components/_formElements/_newDesign2/OptionSwitcherND2';
import ButtonMultipleND2 from '../../_components/_formElements/_newDesign2/ButtonMultipleND2';
import UnitImagePreviewCard from './_components/_cards/UnitImagePreviewCard';
import AddLandlordModal, { RequesterData } from './_components/_modals/AddLandlordModal';
import ImageUploaderModal, { IUploadedImage } from './_components/_modals/ImageUploaderModal';
import ImageEditModal from './_components/_modals/ImageEditModal';
import DarkerDisabledTextFieldND2 from '../../_components/_formElements/_newDesign2/DarkerDisabledTextFieldND2';
import ImagePreview from '../../_components/ImagePreview';

/**
 * Utils
*/
import { useNavigate } from 'react-router-dom';
import DefaultAxios from '../../_utils/DefaultAxios';
import { currencyToNumber, numberToCurrency, generalErrorHandler, ceilToNearest } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';
import Swal from 'sweetalert2';

/**
 * Icons
*/
import { AddCircle } from '@mui/icons-material';

/**
 * Images
 */
import uploadImage from '../../Assets/Images/svg/image-upload.svg'

interface Props {
    unitId: string | null;
    mode: 'add' | 'edit';
    state?: IState;
    changeFormDetailChanged?: Function;
    isListingSubmissionApartment?: boolean
    requesterData?: RequesterData
}

export interface IState {
    [key: string]: any
    signing_date: Date | null;
    category: number;
    pic_unit: null | number;
    pic_unit_name: string;
    apartment_id: null | number;
    apartment_name: string;
    is_owner: number;
    name: string;
    tower: string;
    bedroom: number;
    floor_level: string;
    maid_room: number;
    unit_number: string;
    bathroom: number;
    unit_area: string;
    view_direction: string;
    furnished: string;
    is_rented_guarantee: number;
    landlord_id: null | number;
    landlord_label: string;
    agent_id: null | string;
    agent_label: string;
    assigned_advisor_id: null | string;
    assigned_advisor_label: string;
    service_charge_type: number;
    service_charge: string;
    deposit_charge: string;
    is_rent: boolean;
    is_special_price: boolean;
    is_negotiable: boolean;
    is_dollar: boolean;
    is_sell: boolean;
    is_additional_data: boolean;
    is_meta: boolean;
    basic_price_1: string;
    basic_price_3: string;
    basic_price_6: string;
    basic_price_12: string;
    basic_price_sell: string;
    price_per_day: string;
    price_per_1_month_in_dollar: string;
    price_per_1_month: string;
    price_per_3_months: string;
    price_per_6_months: string;
    price_per_12_months: string;
    selling_price: string;
    certificate: number;
    facilities: string[];
    highlight: string;
    highlight_en: string;
    keterangan: string;
    meta_title: string;
    meta_keyword: string;
    meta_description: string;
    meta_title_en: string;
    meta_description_en: string;
    support_daily: boolean;
    support_1: boolean;
    support_3: boolean;
    support_6: boolean;
    code: string
    galleries: {
        caption: string
        file: File
        type: string
    }[]
    source_input: string
}

interface IErrorState {
    [key: string]: any
    apartment_id: string;
    landlord_id: string;
    assigned_advisor_id: string;
    agent_id: string;
    pic_unit: string;
    view_direction: string;
    floor_level: string;
    unit_number: string;
    furnished: string
    price_per_1_month_err: string;
    basic_price_1_err: string;
    basic_price_3_err: string
    basic_price_6_err: string
    basic_price_12_err: string
    price_per_3_months_err: string
    price_per_6_months_err: string
    price_per_12_months_err: string
    selling_price_err: string
    basic_price_sell_err: string
    source_input: string
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const useStyles = generateStyle((theme: Theme) =>
({
    borderBottomLine: {
        borderBottom: '1px solid #eee',
        marginBottom: 0
    },
    red: {
        color: 'red'
    },
    submitButton: {
        paddingLeft: '25px',
        paddingRight: '25px'
    },
    expandDiv: {
        textAlign: 'center'
    },
    inputPrice: {
        marginBottom: '15px'
    },
    tooltip: {
        color: '#2a6496',
        cursor: 'pointer'
    },
    ".MuiInputBase-root.Mui-disabled": {
        backgroundColor: '#eee'
    },
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    subtitle: {
        fontSize: '20px'
    },
    smallSubtitle: {
        fontSize: '16px'
    },
    infoText: {
        color: '#000000',
        fontSize: '14px',
        opacity: '40%'
    },
    title1: {
        fontSize: 22,
        fontWeight: '500',
        margin: 0,
        paddingTop: 10,
        paddingBottom: 10,
    },
    subtitle1: {
        fontSize: 14,
        color: '#909090',
        marginTop: 0,
        paddingBottom: 10,
    },
    subtitle2: {
        fontSize: 14,
        color: '#484848',
        marginTop: 0,
        marginBottom: 12,
        paddingBottom: 12,
        fontWeight: 500,
    },
    spacing24: {
        paddingBottom: 24,
    },
    spacing40: {
        paddingBottom: 40,
    },
    spacing60: {
        paddingBottom: 60,
    },
}), "UnitDetailForm"
);

const UnitDetailForm = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/unit';
    const { Root, classes } = useStyles();
    const navigate = useNavigate();
    const priceRef = useRef<any>(null)
    const [loading, setLoading] = useState(false);
    const [firstInit, setFirstInit] = useState(true);
    const [addLandlordModal, setAddLandlordModal] = useState(false)
    const [uploadImageModal, setUploadImageModal] = useState(false)
    const [editImage, setEditImage] = useState<{
        data: IUploadedImage | null,
        index: number
    }>({ data: null, index: 0 })
    const [previewImage, setPreviewImage] = useState('')

    const currency = [
        'service_charge',
        'deposit_charge',
        'basic_price_1',
        'basic_price_3',
        'basic_price_6',
        'basic_price_12',
        'basic_price_sell',
        'price_per_day',
        'price_per_1_month_in_dollar',
        'price_per_1_month',
        'price_per_3_months',
        'price_per_6_months',
        'price_per_12_months',
        'selling_price'
    ];

    const facilities = [
        {
            value: 'tempat-tidur',
            label: 'Tempat Tidur'
        },
        {
            value: 'lemari',
            label: 'Lemari'
        },
        {
            value: 'sofa',
            label: 'Sofa'
        },
        {
            value: 'dining-set',
            label: 'Dining Set'
        },
        {
            value: 'ac',
            label: 'AC'
        },
        {
            value: 'tv',
            label: 'TV'
        },
        {
            value: 'kulkas',
            label: 'Kulkas'
        },
        {
            value: 'microwave',
            label: 'Microwave'
        },
        {
            value: 'stove',
            label: 'Stove'
        },
        {
            value: 'mesin-cuci',
            label: 'Mesin Cuci'
        },
        {
            value: 'air-panas',
            label: 'Air Panas'
        },
        {
            value: 'access-card',
            label: 'Access Card'
        },
        {
            value: 'private-lift',
            label: 'Private Lift'
        },
    ];

    const initialState = {
        signing_date: null,
        category: 0,
        pic_unit: null,
        apartment_id: null,
        is_owner: 1,
        name: '',
        tower: '',
        bedroom: 0,
        floor_level: '',
        maid_room: 0,
        unit_number: '',
        bathroom: 1,
        unit_area: '',
        view_direction: '',
        furnished: '',
        is_rented_guarantee: 2,
        landlord_id: null,
        landlord_label: '',
        agent_id: null,
        agent_label: '',
        assigned_advisor_id: null,
        assigned_advisor_label: '',
        service_charge_type: -1,
        service_charge: '',
        deposit_charge: '',
        is_rent: false,
        is_special_price: false,
        is_negotiable: false,
        is_dollar: false,
        is_sell: false,
        is_additional_data: false,
        is_meta: false,
        basic_price_1: '',
        basic_price_3: '',
        basic_price_6: '',
        basic_price_12: '',
        basic_price_sell: '',
        price_per_day: '',
        price_per_1_month_in_dollar: '',
        price_per_1_month: '',
        price_per_3_months: '',
        price_per_6_months: '',
        price_per_12_months: '',
        selling_price: '',
        certificate: 0,
        facilities: [],
        highlight: '',
        highlight_en: '',
        keterangan: '',
        meta_title: '',
        meta_keyword: '',
        meta_description: '',
        meta_title_en: '',
        meta_description_en: '',
        support_daily: false,
        support_1: false,
        support_3: false,
        support_6: false,
        pic_unit_name: '',
        apartment_name: '',
        code: '',
        galleries: [],
        source_input: 'default',
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            if (['bedroom', 'apartment_id', 'furnished', 'maid_room'].includes(action.name)) {
                return {
                    ...state,
                    name: '',
                    [action.name]: action.value
                }
            } else {
                return {
                    ...state,
                    [action.name]: action.value
                }
            }

        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                apartment_id: '',
                landlord_id: '',
                assigned_advisor_id: '',
                agent_id: '',
                pic_unit: '',
                view_direction: '',
                floor_level: '',
                unit_number: '',
                furnished: '',
                price_per_1_month_err: '',
                basic_price_1_err: '',
                basic_price_3_err: '',
                basic_price_6_err: '',
                basic_price_12_err: '',
                price_per_3_months_err: '',
                price_per_6_months_err: '',
                price_per_12_months_err: '',
                selling_price_err: '',
                basic_price_sell_err: '',
                source_input: 'default',
            };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, {
        apartment_id: '',
        landlord_id: '',
        assigned_advisor_id: '',
        agent_id: '',
        pic_unit: '',
        view_direction: '',
        floor_level: '',
        unit_number: '',
        furnished: '',
        price_per_1_month_err: '',
        basic_price_1_err: '',
        basic_price_3_err: '',
        basic_price_6_err: '',
        basic_price_12_err: '',
        price_per_3_months_err: '',
        price_per_6_months_err: '',
        price_per_12_months_err: '',
        selling_price_err: '',
        basic_price_sell_err: '',
        source_input: '',
    });


    useEffect(() => {
        if (props.state) {
            setInputState({ name: '', value: props.state, type: 'REPLACE_STATE' });

            // Delay set first init so price doesnt calc immediately
            const timer = setTimeout(() => setFirstInit(false), 500)

            return () => {
                clearTimeout(timer)
            };
        } else {
            setFirstInit(false);
        }
    }, [props.state]);

    useEffect(() => {
        if (!firstInit && inputState.is_rent && (inputState.category === 1 || inputState.category === 3)) {
            const oldState = { ...inputState };

            if (!oldState.support_6) {
                oldState.price_per_6_months = '';
                oldState.basic_price_6 = '';
            }

            if (!oldState.support_3) {
                oldState.price_per_3_months = '';
                oldState.basic_price_3 = '';
            }

            if (!oldState.support_1) {
                oldState.price_per_1_month = '';
                oldState.basic_price_1 = '';
            }

            if (!oldState.support_daily) {
                oldState.price_per_day = '';
            }

            setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [inputState.support_daily, inputState.support_1, inputState.support_3, inputState.support_6, inputState.is_dollar/* , inputState.price_per_12_months */]);

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = (event.target as HTMLInputElement);
        let value: any = target.value;

        if (props.changeFormDetailChanged) {
            props.changeFormDetailChanged(true);
        }

        if (['is_rented_guarantee'].includes(target.name)) {
            value = +value;
        } else if (currency.includes(target.name)) {
            value = value ? numberToCurrency(value) : '';
        } else if (target.name === 'is_owner') {
            value = event.target.checked ? 1 : 0;
        } else if (target.name === 'facilities') {
            const tmp = inputState.facilities.slice();
            if (target.checked) {
                tmp.push(target.value);
            } else {
                const index = tmp.indexOf(target.value);
                if (index > -1) {
                    tmp.splice(index, 1);
                }
            }
            value = tmp;
        } else if (target.type === 'checkbox') {
            value = target.checked;
        } else if (target.name === 'unit_area') {
            value = target.value.replace(/[^\d.]/g, "")
        }

        switch (target.name) {
            case 'basic_price_12':
                if (currencyToNumber(value) >= 1000000) {
                    setInputState({ name: 'price_per_12_months', value: numberToCurrency(ceilToNearest((currencyToNumber(value) / 0.85), 100000).toFixed(0)), type: 'SET_ITEM' });
                }
                break
            case 'basic_price_6':
                if (currencyToNumber(value) >= 1000000) {
                    setInputState({ name: 'price_per_6_months', value: numberToCurrency(ceilToNearest((currencyToNumber(value) / 0.80), 100000).toFixed(0)), type: 'SET_ITEM' });
                }
                break
            case 'basic_price_3':
                if (currencyToNumber(value) >= 1000000) {
                    setInputState({ name: 'price_per_3_months', value: numberToCurrency(ceilToNearest((currencyToNumber(value) / 0.75), 100000).toFixed(0)), type: 'SET_ITEM' });
                }
                break
        }

        setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
    };

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const handleAllFacilities = () => {
        if (inputState.facilities.length === facilities.length) {
            setInputState({ name: 'facilities', value: [], type: 'SET_ITEM' });
        } else {
            const newFacilities: string[] = []

            facilities.map(item => {
                newFacilities.push(item.value)
                return true
            })

            setInputState({ name: 'facilities', value: newFacilities, type: 'SET_ITEM' });
        }
    }

    const rentPricesDisabled = (name: string) => {
        let disabled = true;

        if (inputState.is_rent) {
            if (inputState.category === 1 || inputState.category === 3) {
                if (name === 'price_per_day' && inputState.support_daily) {
                    disabled = false;
                }

                if (name === 'price_per_1_month_in_dollar') {
                    disabled = true;
                }

                if (name === 'price_per_1_month' && inputState.support_1) {
                    disabled = false;
                }

                if (name === 'price_per_3_months' && inputState.support_3) {
                    disabled = false;
                }

                if (name === 'price_per_6_months' && inputState.support_6) {
                    disabled = false;
                }

                if (name === 'price_per_12_months') {
                    disabled = false;
                }
            } else {
                disabled = false;
            }
        }

        return disabled;
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...errorState };
        const basic_error = 'Harga basic tidak boleh lebih besar dari harga sewa';
        const rent_error = 'Harga sewa tidak boleh lebih kecil dari harga basic';
        const price_error = 'Harap mengisi harga basic';

        // let keys = ['property_length', 'property_width'];

        if (!inputState.apartment_id) {
            newError.apartment_id = 'Apartment harus diisi';
            isValid = false;
        } else {
            newError.apartment_id = '';
        }

        // if ([1, 2].includes(inputState.category) && !inputState.pic_unit) {
        //     newError.pic_unit = 'PIC Unit harus diisi apabila category starlisting / verified';
        //     isValid = false;
        // } else {
        //     newError.pic_unit = '';
        // }

        if (inputState.is_owner && !inputState.landlord_id) {
            newError.landlord_id = 'Landlord harus diisi apabila PIC status adalah owner';
            isValid = false;
        } else {
            newError.landlord_id = '';
        }

        if (!inputState.is_owner && !inputState.agent_id) {
            newError.agent_id = 'Agent harus diisi apabila PIC status adalah agent';
            isValid = false;
        } else {
            newError.agent_id = '';
        }

        if (!inputState.view_direction) {
            newError.view_direction = 'View direction harus diisi';
            isValid = false;
        } else {
            newError.view_direction = '';
        }

        if (!inputState.floor_level) {
            newError.floor_level = 'Floor level harus diisi';
            isValid = false;
        } else {
            newError.floor_level = '';
        }

        if (!inputState.unit_number) {
            newError.unit_number = 'Unit number harus diisi';
            isValid = false;
        } else {
            newError.unit_number = '';
        }

        if (!inputState.furnished) {
            newError.furnished = 'Furnished harus diisi';
            isValid = false;
        } else {
            newError.furnished = '';
        }

        if (inputState.source_input === 'default') {
            newError.source_input = 'Sumber unit harus diisi';
            isValid = false;
        } else {
            newError.source_input = '';
        }

        const validations = [
            {
                price: 'price_per_1_month',
                basicPrice: 'basic_price_1'
            },
            {
                price: 'price_per_3_months',
                basicPrice: 'basic_price_3'
            },
            {
                price: 'price_per_6_months',
                basicPrice: 'basic_price_6'
            },
            {
                price: 'price_per_12_months',
                basicPrice: 'basic_price_12'
            },
            {
                price: 'selling_price',
                basicPrice: 'basic_price_sell'
            },
        ]

        validations.forEach(validation => {
            if (validation.price === 'selling_price' && !inputState.is_sell) {
                return;
            }

            if (inputState[validation.price] && !inputState[validation.basicPrice] && props.unitId && inputState.assigned_advisor_id) {
                newError[validation.basicPrice + '_err'] = validation.price.includes('sell') ? price_error.replace('sewa', 'jual') : price_error
                isValid = false
                priceRef.current.scrollIntoView()
            } else if (currencyToNumber(inputState[validation.basicPrice]) > currencyToNumber(inputState[validation.price])) {
                newError[validation.basicPrice + '_err'] = validation.price.includes('sell') ? basic_error.replace('sewa', 'jual') : basic_error
                newError[validation.price + '_err'] = validation.price.includes('sell') ? rent_error.replace('sewa', 'jual') : rent_error
                isValid = false;
                priceRef.current.scrollIntoView()
            } else {
                newError[validation.basicPrice + '_err'] = '';
                newError[validation.price + '_err'] = '';
            }
        })

        const priceValidations = ['basic_price_1', 'basic_price_3', 'basic_price_6', 'basic_price_12', 'price_per_1_month', 'price_per_3_months', 'price_per_6_months', 'price_per_12_months', 'basic_price_sell', 'selling_price']

        priceValidations.forEach(priceType => {
            const amount = Number(currencyToNumber(inputState[priceType]))
            if (amount === 0) return

            if (!!inputState.is_dollar) {
                if (amount > 199000) {
                    newError[priceType + '_err'] = 'Nominal tidak bisa lebih dari 199.000'
                    isValid = false;
                } else {
                    newError[priceType + '_err'] = newError[priceType + '_err'] || ''
                }
            } else {
                if (amount < 199000) {
                    newError[priceType + '_err'] = 'Nominal tidak bisa kurang dari 199.000'
                    isValid = false;
                } else {
                    newError[priceType + '_err'] = newError[priceType + '_err'] || ''
                }
            }
        })

        if (inputState.is_rent) {
            if (inputState.basic_price_12 === '') {
                newError['basic_price_12_err'] = 'Harga 12 bulan wajib diisi'
                isValid = false;
            } else {
                newError['basic_price_12_err'] = newError['basic_price_12_err'] || ''
            }

            if (inputState.price_per_12_months === '') {
                newError['price_per_12_months_err'] = 'Harga 12 bulan wajib diisi'
                isValid = false;
            } else {
                newError['price_per_12_months_err'] = newError['price_per_12_months_err'] || ''
            }
        }

        if (inputState.service_charge_type === -1) {
            newError['service_charge_type'] = 'Harap memilih tipe Service Charge'
            isValid = false;
        } else {
            newError['service_charge_type'] = ''
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const clearData = (data: any) => {
        if (data.is_owner) {
            data.agent_id = null;
        } else {
            data.landlord_id = null;
        }

        if (data.service_charge_type !== 1) {
            data.service_charge = '';
        }

        // Cast all boolean to 0 and 1
        Object.entries(data).forEach(([key, value]) => {
            if (typeof value === 'boolean') {
                data[key] = value ? 1 : 0
            }
        })

        return data;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        const { galleries, ...restInputState } = inputState
        let data: any = { ...restInputState };

        if (props.mode === 'add') {
            data['_action'] = 'addProcess';
        } else {
            data['_action'] = 'editProcess';
            data['id'] = props.unitId;
        }

        for (let key in data) {
            if (currency.includes(key)) {
                data[key as keyof IState] = currencyToNumber(data[key as keyof IState]);
            }
        }

        data = clearData(data);

        const fd = new FormData()

        // Move data to form data
        Object.entries(data).forEach(([key, value]) => {
            switch (key) {
                case 'facilities':
                    // Change facilities to form data array
                    (value as string[]).forEach((facility: string, index: number) => {
                        fd.append(`facilities[${index}]`, facility)
                    })
                    break
                default:
                    if (value !== null) fd.append(key, String(value))
                    break
            }

        })

        if (props.mode === 'add') {
            // Process the images
            galleries.forEach((gallery, index) => {
                fd.append(`param[${index}][caption]`, gallery.caption)
                // fd.append(`param[${index}][thumbnail]`, value as File)
                fd.append(`param[${index}][image]`, gallery.file)
                fd.append(`param[${index}][room_type]`, gallery.type)
            })
        }

        setLoading(true);
        DefaultAxios.post(API_URL + '/submit', fd)
            .then(res => res.data)
            .then(id => {
                Swal.fire({
                    title: "Unit Submitted",
                    icon: 'success',
                    timer: 1000
                });

                if (props.mode === 'add') {
                    navigate(`${props.isListingSubmissionApartment ? '/listing-submission-apartment/' : '/unit/'}edit/${id}/gallery`)
                } else {
                    if (props.isListingSubmissionApartment) {
                        navigate(`/listing-submission-apartment/${props.state?.submission_code || ''}`)
                    } else {
                        navigate(`/unit/${props.state?.code || ''}`)
                    }
                }
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        const newError = { ...errorState };
        const basic_error = 'Harga basic tidak boleh lebih besar dari harga sewa';
        const rent_error = 'Harga sewa tidak boleh lebih kecil dari harga basic';
        const price_error = 'Harap mengisi harga basic';

        const validations = [
            {
                price: 'price_per_1_month',
                basicPrice: 'basic_price_1'
            },
            {
                price: 'price_per_3_months',
                basicPrice: 'basic_price_3'
            },
            {
                price: 'price_per_6_months',
                basicPrice: 'basic_price_6'
            },
            {
                price: 'price_per_12_months',
                basicPrice: 'basic_price_12'
            },
            {
                price: 'selling_price',
                basicPrice: 'basic_price_sell'
            },
        ]

        validations.forEach(validation => {
            if (inputState[validation.price] && !inputState[validation.basicPrice] && props.unitId && inputState.assigned_advisor_id) {
                newError[validation.basicPrice + '_err'] = validation.price.includes('sell') ? price_error.replace('sewa', 'jual') : price_error
            } else if (currencyToNumber(inputState[validation.basicPrice]) > currencyToNumber(inputState[validation.price])) {
                newError[validation.basicPrice + '_err'] = validation.price.includes('sell') ? basic_error.replace('sewa', 'jual') : basic_error
                newError[validation.price + '_err'] = validation.price.includes('sell') ? rent_error.replace('sewa', 'jual') : rent_error
            } else {
                newError[validation.basicPrice + '_err'] = '';
                newError[validation.price + '_err'] = '';
            }
        })

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        // eslint-disable-next-line
    }, [inputState])

    const onChange = (name: string, value: string | number | boolean | string[]) => {
        const isBoolean = typeof value === 'boolean'

        if (props.changeFormDetailChanged) {
            props.changeFormDetailChanged(true);
        }

        if (['is_rented_guarantee'].includes(name)) {
            value = +value;
        } else if (currency.includes(name)) {
            value = value ? numberToCurrency(value as string | number) : '';
        } else if (name === 'is_owner') {
            value = value ? 1 : 0;
        } else if (name === 'facilities') {
            const tmp = inputState.facilities.slice();
            if (!tmp.includes(value.toString())) {
                tmp.push(value.toString());
            } else {
                const index = tmp.indexOf(value.toString());
                if (index > -1) {
                    tmp.splice(index, 1);
                }
            }
            value = tmp;
        } else if (isBoolean) {
            // Skip
        } else if (name === 'unit_area') {
            value = value.toString().replace(/[^\d.]/g, "")
        }

        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleChangeImageGalleries = (type: string, data: IUploadedImage, index: number) => {
        switch (type) {
            case 'edit':
                setEditImage({
                    data,
                    index
                })
                break;
            case 'sampul':
                const newGalleries = [...inputState.galleries]
                newGalleries.unshift(newGalleries.splice(index, 1)[0])
                setInputState({ type: 'SET_ITEM', name: 'galleries', value: newGalleries })
                break;
            case 'delete':
                let filteredNewGalleries = [...inputState.galleries]
                filteredNewGalleries = filteredNewGalleries.filter((_, idx) => idx !== index)
                setInputState({ type: 'SET_ITEM', name: 'galleries', value: filteredNewGalleries })
                break
            default:
                return
        }
    }

    const onAddLandlordSuccess = (id: number, label: string) => {
        setInputState({ name: 'landlord_id', value: id, type: 'SET_ITEM' });
        setInputState({ name: 'landlord_label', value: label, type: 'SET_ITEM' });
    }

    return (
        <Root>
            <LoadingScreen open={loading} fullScreen />
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <h2 className={`${classes.borderBottomLine} ${classes.title1}`}>Unit Details</h2>
                </Grid>

                {
                    props.mode === 'add' ?
                        <>
                            <Grid item xs={12} md={4}>
                                <TextFieldND2
                                    label="Sumber Unit"
                                    name="source_input"
                                    value={inputState.source_input}
                                    onChange={handleChanged}
                                    select
                                    placeholder='Pilih sumber unit'
                                    error={!!errorState.source_input}
                                    helperText={errorState.source_input}
                                >
                                    <MenuItem value='default' disabled>Pilih sumber unit</MenuItem>
                                    <MenuItem value="listing_submission">Listing Submission</MenuItem>
                                    <MenuItem value="raw_database">Raw Database</MenuItem>
                                    <MenuItem value="cs">CS</MenuItem>
                                    <MenuItem value="internal_reference">Referensi Internal (e.g: PM, TR, Telesales)</MenuItem>
                                    <MenuItem value="external_reference">Referensi External (e.g: Owner, Developer, BM, dll.)</MenuItem>
                                    <MenuItem value="bda">Data BDA</MenuItem>
                                </TextFieldND2>
                            </Grid>

                            <Grid item xs={0} md={8} display={{ xs: 'none', md: 'block' }} />
                        </>
                        : null
                }

                <Grid item xs={12}>
                    <ButtonRadioND2
                        label='Jenis Unit'
                        name='category'
                        value={inputState.category}
                        onChange={onChange}
                        options={[
                            {
                                label: 'Reguler',
                                value: 0,
                            },
                            {
                                label: 'Star Listing',
                                value: 1,
                            },
                        ]}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AsyncAutoComplete
                        label="Apartment"
                        name="apartment_id"
                        initialQuery={inputState.apartment_name}
                        onChange={handleAutocomplete}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/apartment`}
                        placeholder="Apartment Name"
                        errorText={errorState.apartment_id}
                        newDesign={2}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextFieldND2
                        label="Title"
                        name="name"
                        value={inputState.name}
                        onChange={handleChanged}
                        placeholder="Tidak perlu diisi, akan terisi secara otomatis"
                    />
                </Grid>

                <Grid item xs={3}>
                    <OptionSwitcherND2
                        label='Bedroom'
                        name='bedroom'
                        value={inputState.bedroom}
                        onChange={onChange}
                        options={['Studio', '1 Bedroom', '2 Bedrooms', '3 Bedrooms', '4 Bedrooms', '5 Bedrooms'].map((bedroom, index) => ({
                            label: bedroom,
                            value: index,
                        }))}
                    />
                </Grid>
                <Grid item xs={3}>
                    <OptionSwitcherND2
                        label='Bathroom'
                        name='bathroom'
                        value={inputState.bathroom}
                        onChange={onChange}
                        options={['1 Bathroom', '2 Bathrooms', '3 Bathrooms', '4 Bathrooms', '5 Bathrooms'].map((bathroom, index) => ({
                            label: bathroom,
                            value: index + 1, // 'None' (0) is taken out, so '1 Bathroom' has value 1
                        }))}
                    />
                </Grid>
                <Grid item xs={3}>
                    <OptionSwitcherND2
                        label='Maid Room'
                        name='maid_room'
                        value={inputState.maid_room}
                        onChange={onChange}
                        options={['None', '1 Maid Room', '2 Maid Rooms', '3 Maid Rooms', '4 Maid Rooms', '5 Maid Rooms'].map((maidRoom, index) => ({
                            label: maidRoom,
                            value: index,
                        }))}
                    />
                </Grid>
                <Grid item xs={3} />

                <Grid item xs={4}>
                    <TextFieldND2
                        label="Tower"
                        name="tower"
                        value={inputState.tower}
                        onChange={handleChanged}
                        placeholder="Tower Name"
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextFieldND2
                        label="Floor Level"
                        name="floor_level"
                        value={inputState.floor_level}
                        onChange={handleChanged}
                        placeholder="Floor Level"
                        error={!!errorState.floor_level}
                        helperText={errorState.floor_level}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextFieldND2
                        label="Unit Number"
                        name="unit_number"
                        value={inputState.unit_number}
                        onChange={handleChanged}
                        placeholder="Unit Number"
                        error={!!errorState.unit_number}
                        helperText={errorState.unit_number}
                    />
                </Grid>

                <Grid item xs={12}>
                    <ButtonRadioND2
                        label="Furnished"
                        name="furnished"
                        value={inputState.furnished}
                        onChange={onChange}
                        options={[
                            {
                                label: 'Fully Furnished',
                                value: 'full',
                            },
                            {
                                label: 'Semi Furnished',
                                value: 'semi',
                            },
                            {
                                label: 'Non Furnished',
                                value: 'non',
                            },
                        ]}
                        error={errorState.furnished}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldND2
                        label="View Direction"
                        name="view_direction"
                        value={inputState.view_direction}
                        onChange={handleChanged}
                        placeholder="View Direction"
                        error={!!errorState.view_direction}
                        helperText={errorState.view_direction}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextFieldND2
                        label="Unit Size"
                        name="unit_area"
                        value={inputState.unit_area}
                        onChange={handleChanged}
                        placeholder="Unit Area in square meter"
                    />
                </Grid>

                <Grid item xs={12}>
                    <h2 className={`${classes.title1}`} style={{ marginBottom: 8 }}>Owner Information</h2>
                    <ButtonRadioND2
                        name="is_owner"
                        value={inputState.is_owner}
                        onChange={onChange}
                        options={[
                            {
                                label: 'Landlord',
                                value: 1,
                            },
                            {
                                label: 'Agent',
                                value: 0,
                            },
                        ]}
                    />
                </Grid>

                {inputState.is_owner === 1 ?
                    <>
                        <Grid item xs={6}>
                            <AsyncAutoComplete
                                key={1}
                                label="Landlord"
                                name="landlord_id"
                                initialQuery={inputState.landlord_label}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/landlord`}
                                placeholder="Isi Nama / Email / Nomor Telepon"
                                errorText={errorState.landlord_id}
                                newDesign={2}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Button
                                variant='outlined'
                                color='primary'
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: 6 / 4,
                                    paddingTop: 8 / 8,
                                    paddingBottom: 8 / 8,
                                }}
                                onClick={() => setAddLandlordModal(true)}
                            >
                                <AddCircle color='primary' fontSize={'small'} sx={{ marginRight: 1 }} /> Add Landlord
                            </Button>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={6}>
                            <AsyncAutoComplete
                                key={0}
                                label="Agent"
                                name="agent_id"
                                initialQuery={inputState.agent_label}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/agent`}
                                placeholder="Isi Nama / Email / Nomor Telepon"
                                errorText={errorState.agent_id}
                                newDesign={2}
                            />
                        </Grid>
                        <Grid item xs={6} />
                    </>
                }

                <Grid item xs={12}>
                    <h2 className={`${classes.borderBottomLine} ${classes.title1}`}>Prices</h2>
                </Grid>

                <Grid item xs={12}>
                    <ButtonRadioND2
                        label="Service Charge Type"
                        name="service_charge_type"
                        value={inputState.service_charge_type}
                        onChange={onChange}
                        options={[
                            {
                                label: 'Setting Sesuai Apartment',
                                value: 0,
                            },
                            {
                                label: 'Free',
                                value: 2,
                            },
                            {
                                label: 'Custom',
                                value: 1,
                            },
                        ]}
                        error={errorState.service_charge_type}
                    />
                </Grid>

                {
                    inputState.service_charge_type === 1
                        ? <>
                            <Grid item xs={6}>
                                <TextFieldND2
                                    label="Service Charge"
                                    name="service_charge"
                                    value={inputState.service_charge}
                                    onChange={handleChanged}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Rp.</InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} />
                        </>
                        : null
                }

                <Grid item xs={6}>
                    <TextFieldND2
                        label="Deposit Charge"
                        name="deposit_charge"
                        value={inputState.deposit_charge}
                        onChange={handleChanged}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rp.</InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={6} />

                <Grid item xs={6} className={classes.switchContainer} style={{ paddingBottom: 0 }}>
                    <Typography className={classes.smallSubtitle}>
                        Dollar
                        <br />
                        <Typography component="p" className={classes.infoText}>Unit akan ditampilkan dengan dollar</Typography>
                    </Typography>
                    <Switch
                        color="primary"
                        // value={true}
                        name="is_dollar"
                        checked={inputState.is_dollar}
                        onChange={handleChanged}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                </Grid>
                <Grid item xs={6} />

                {/* <Grid item xs={6} className={classes.switchContainer} style={{ paddingBottom: 0 }}>
                    <Typography className={classes.smallSubtitle}>
                        Nego
                        <br />
                        <Typography component="p" className={classes.infoText}>Unit akan ditandai dapat di Nego</Typography>
                    </Typography>
                    <Switch
                        color="primary"
                        // value={true}
                        name="is_negotiable"
                        checked={inputState.is_negotiable}
                        onChange={handleChanged}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                </Grid>
                <Grid item xs={6} /> */}

                <Grid item xs={6} className={classes.switchContainer} style={{ paddingBottom: 0 }}>
                    <Typography className={classes.smallSubtitle}>
                        Unit Disewakan
                    </Typography>
                    <Switch
                        color="primary"
                        // value={true}
                        name="is_rent"
                        checked={inputState.is_rent}
                        onChange={handleChanged}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Collapse in={inputState.is_rent} timeout="auto" unmountOnExit>
                        <Grid container spacing={4} style={{ marginTop: -8 }}>
                            <Grid item xs={12} md={6} ref={priceRef} style={{ paddingBottom: 0 }}>
                                <Typography className={`${classes.subtitle2} ${classes.borderBottomLine}`}>
                                    Basic Price
                                </Typography>
                                <DarkerDisabledTextFieldND2
                                    label={`Harga 12 Bulan`}
                                    name="basic_price_12"
                                    value={inputState.basic_price_12}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    disabled={rentPricesDisabled('price_per_12_months')}
                                    className={classes.inputPrice}
                                    error={!!errorState.basic_price_12_err}
                                    helperText={errorState.basic_price_12_err}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{inputState.is_dollar ? '$' : 'Rp'}</InputAdornment>
                                    }}
                                />
                                <DarkerDisabledTextFieldND2
                                    label={`Harga 6 Bulan`}
                                    name="basic_price_6"
                                    value={inputState.basic_price_6}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    disabled={rentPricesDisabled('price_per_6_months')}
                                    className={classes.inputPrice}
                                    error={!!errorState.basic_price_6_err}
                                    helperText={errorState.basic_price_6_err}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{inputState.is_dollar ? '$' : 'Rp'}</InputAdornment>
                                    }}
                                />
                                <DarkerDisabledTextFieldND2
                                    label={`Harga 3 Bulan`}
                                    name="basic_price_3"
                                    value={inputState.basic_price_3}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    disabled={rentPricesDisabled('price_per_3_months')}
                                    className={classes.inputPrice}
                                    error={!!errorState.basic_price_3_err}
                                    helperText={errorState.basic_price_3_err}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{inputState.is_dollar ? '$' : 'Rp'}</InputAdornment>
                                    }}
                                />
                                <DarkerDisabledTextFieldND2
                                    label={`Harga Bulanan`}
                                    name="basic_price_1"
                                    value={inputState.basic_price_1}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    disabled={rentPricesDisabled('price_per_1_month')}
                                    className={classes.inputPrice}
                                    error={!!errorState.basic_price_1_err}
                                    helperText={errorState.basic_price_1_err}
                                    style={{ marginBottom: 0 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{inputState.is_dollar ? '$' : 'Rp'}</InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography className={`${classes.subtitle2} ${classes.borderBottomLine}`}>
                                    Web Price
                                </Typography>
                                <DarkerDisabledTextFieldND2
                                    label={`Harga 12 Bulan`}
                                    name="price_per_12_months"
                                    value={inputState.price_per_12_months}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    disabled={rentPricesDisabled('price_per_12_months')}
                                    className={classes.inputPrice}
                                    error={!!errorState.price_per_12_months_err}
                                    helperText={errorState.price_per_12_months_err}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{inputState.is_dollar ? '$' : 'Rp'}</InputAdornment>
                                    }}
                                />
                                <DarkerDisabledTextFieldND2
                                    label={`Harga 6 Bulan`}
                                    name="price_per_6_months"
                                    value={inputState.price_per_6_months}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    disabled={rentPricesDisabled('price_per_6_months')}
                                    className={classes.inputPrice}
                                    error={!!errorState.price_per_6_months_err}
                                    helperText={errorState.price_per_6_months_err}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{inputState.is_dollar ? '$' : 'Rp'}</InputAdornment>
                                    }}
                                />
                                <DarkerDisabledTextFieldND2
                                    label={`Harga 3 Bulan`}
                                    name="price_per_3_months"
                                    value={inputState.price_per_3_months}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    disabled={rentPricesDisabled('price_per_3_months')}
                                    className={classes.inputPrice}
                                    error={!!errorState.price_per_3_months_err}
                                    helperText={errorState.price_per_3_months_err}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{inputState.is_dollar ? '$' : 'Rp'}</InputAdornment>
                                    }}
                                />
                                <DarkerDisabledTextFieldND2
                                    label={`Harga Bulanan`}
                                    name="price_per_1_month"
                                    value={inputState.price_per_1_month}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    disabled={rentPricesDisabled('price_per_1_month')}
                                    className={classes.inputPrice}
                                    error={!!errorState.price_per_1_month_err}
                                    helperText={errorState.price_per_1_month_err}
                                    style={{ marginBottom: 0 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{inputState.is_dollar ? '$' : 'Rp'}</InputAdornment>
                                    }}
                                />
                            </Grid>

                            {
                                inputState.category === 1 || inputState.category === 3 // Starlisting or SLOH
                                    ?
                                    <Grid item xs={12}>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={inputState.support_6}
                                                        onChange={handleChanged}
                                                        name="support_6"
                                                        color="primary"
                                                    />
                                                }
                                                label="6 Bulan"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={inputState.support_3}
                                                        onChange={handleChanged}
                                                        name="support_3"
                                                        color="primary"
                                                    />
                                                }
                                                label="3 Bulan"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={inputState.support_1}
                                                        onChange={handleChanged}
                                                        name="support_1"
                                                        color="primary"
                                                    />
                                                }
                                                label="1 Bulan"
                                            />
                                            {/* <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={inputState.support_daily}
                                                    onChange={handleChanged}
                                                    name="support_daily"
                                                    color="primary"
                                                />
                                            }
                                            label="Harian"
                                        /> */}
                                        </FormGroup>
                                    </Grid>
                                    : null
                            }

                        </Grid>
                    </Collapse>
                </Grid>

                <Grid item xs={6} className={classes.switchContainer} style={{ paddingBottom: 0 }}>
                    <Typography className={classes.smallSubtitle}>
                        Unit Dijual
                    </Typography>
                    <Switch
                        color="primary"
                        // value={true}
                        name="is_sell"
                        checked={inputState.is_sell}
                        onChange={handleChanged}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Collapse in={inputState.is_sell} timeout="auto" unmountOnExit>
                        <Grid container spacing={3} style={{ marginTop: -8 }}>
                            <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                                <Typography className={`${classes.subtitle2} ${classes.borderBottomLine}`}>
                                    Basic Price
                                </Typography>
                                <DarkerDisabledTextFieldND2
                                    name="basic_price_sell"
                                    value={inputState.basic_price_sell}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    disabled={!inputState.is_sell}
                                    className={classes.inputPrice}
                                    style={{ marginBottom: 0 }}
                                    error={!!errorState.basic_price_sell_err}
                                    helperText={errorState.basic_price_sell_err}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{inputState.is_dollar ? '$' : 'Rp'}</InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                                <Typography className={`${classes.subtitle2} ${classes.borderBottomLine}`}>
                                    Web Price
                                </Typography>
                                <DarkerDisabledTextFieldND2
                                    name="selling_price"
                                    value={inputState.selling_price}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    disabled={!inputState.is_sell}
                                    className={classes.inputPrice}
                                    style={{ marginBottom: 0 }}
                                    error={!!errorState.selling_price_err}
                                    helperText={errorState.selling_price_err}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{inputState.is_dollar ? '$' : 'Rp'}</InputAdornment>
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>

                <Grid item xs={12}>
                    <h2 className={`${classes.borderBottomLine} ${classes.title1}`}>Facilities</h2>
                </Grid>

                <Grid item xs={12} style={{ paddingTop: 20 }}>
                    <ButtonMultipleND2
                        name='facilities'
                        value={facilities.length === inputState.facilities.length ? 'all' : ''}
                        options={[
                            {
                                label: 'Pilih Semua',
                                value: 'all',
                            }
                        ]}
                        onChange={() => {
                            handleAllFacilities()
                        }}
                    />
                    <ButtonMultipleND2
                        name='facilities'
                        value={inputState.facilities}
                        options={facilities}
                        onChange={onChange}
                    />
                </Grid>

                {
                    props.mode === 'add' ?
                        <>
                            <Grid item xs={12}>
                                <h2 className={`${classes.borderBottomLine} ${classes.title1}`}>Image Upload</h2>
                            </Grid>

                            <Grid item xs={12} style={{ paddingTop: 20 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2.4}>
                                        <div onClick={() => setUploadImageModal(true)} style={{ aspectRatio: '1/1', backgroundColor: '#F5F5F5', cursor: 'pointer', border: '1px dashed #B2B2B2', borderRadius: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={uploadImage} alt="upload" />
                                            <p style={{ marginBottom: 0 }}>Add Image</p>
                                        </div>
                                    </Grid>
                                    {
                                        (inputState.galleries && inputState.galleries.length) ?
                                            inputState.galleries.map((gallery: any, idx: number) => (
                                                <Grid item xs={2.4}>
                                                    <UnitImagePreviewCard
                                                        caption={gallery.caption}
                                                        index={idx}
                                                        onChange={(name) => {
                                                            handleChangeImageGalleries(name, gallery, idx)
                                                        }}
                                                        src={URL.createObjectURL(gallery.file)}
                                                        type={gallery.type}
                                                        onPreview={src => setPreviewImage(src)}
                                                    />
                                                </Grid>
                                            )) :
                                            null
                                    }
                                </Grid>
                            </Grid>
                        </>
                        : null
                }

                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                    <h2 className={classes.title1}>Notes</h2>
                    <p className={`${classes.subtitle1} ${classes.borderBottomLine}`}>Tidak Ditampilkan di Website</p>
                </Grid>

                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <TextField
                            label="Notes"
                            multiline
                            name="keterangan"
                            value={inputState.keterangan}
                            variant="outlined"
                            fullWidth
                            onChange={handleChanged}
                            rows={6}
                            placeholder="Keterangan"
                        />
                    </Grid>
                </Grid>

                {/* <Grid item xs={12}>
                    <div className={classes.borderBottomLine} style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: 16 }}>
                            <h2 className={classes.title1}>Additional Datas</h2>
                            <p className={classes.subtitle1} style={{ marginBottom: 0 }}>Munculkan additional datas</p>
                        </div>
                        <Switch
                            color="primary"
                            name="is_additional_data"
                            checked={inputState.is_additional_data}
                            onChange={handleChanged}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                    </div>
                </Grid>
                <Divider variant="fullWidth" />
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Collapse in={inputState.is_additional_data} timeout="auto" unmountOnExit>
                        <Grid container spacing={3} style={{ marginTop: "8px" }}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Description"
                                    multiline
                                    name="highlight"
                                    value={inputState.highlight}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    rows={6}
                                    placeholder="Highlight"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Description (EN)"
                                    multiline
                                    name="highlight_en"
                                    value={inputState.highlight_en}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    rows={6}
                                    placeholder="English Description"
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid> */}

                <Grid item xs={12}>
                    <div className={classes.borderBottomLine} style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: 16 }}>
                            <h2 className={classes.title1}>Meta</h2>
                            <p className={classes.subtitle1} style={{ marginBottom: 0 }}>Munculkan pengaturan meta</p>
                        </div>
                        <Switch
                            color="primary"
                            name="is_meta"
                            checked={inputState.is_meta}
                            onChange={handleChanged}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0 }}>

                    <Collapse in={inputState.is_meta} timeout="auto" unmountOnExit>
                        <Grid container spacing={3} style={{ marginTop: "8px" }}>
                            <Grid item xs={12}>
                                <TextField
                                    name="meta_title"
                                    value={inputState.meta_title}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    placeholder="Meta Title"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name="meta_keyword"
                                    value={inputState.meta_keyword}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    placeholder="Meta Keyword"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name="meta_description"
                                    value={inputState.meta_description}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    placeholder="Meta Description"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name="meta_title_en"
                                    value={inputState.meta_title_en}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    placeholder="Meta Title En"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name="meta_description_en"
                                    value={inputState.meta_description_en}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChanged}
                                    placeholder="Meta Description En"
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>


                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>


            <AddLandlordModal
                open={addLandlordModal}
                onClose={() => setAddLandlordModal(false)}
                onSuccess={onAddLandlordSuccess}
                requesterData={props.isListingSubmissionApartment ? props.requesterData : undefined}
            />

            <ImageUploaderModal
                open={uploadImageModal}
                onClose={() => setUploadImageModal(false)}
                onSubmit={(galleries) => {
                    setInputState({
                        type: 'SET_ITEM',
                        name: 'galleries',
                        value: inputState.galleries ? inputState.galleries.concat(galleries) : galleries
                    })
                }}
            />

            {
                editImage.data !== null ?
                    <ImageEditModal
                        open={editImage.data !== null}
                        data={editImage.data}
                        index={editImage.index}
                        onClose={() => { setEditImage({ data: null, index: 0 }) }}
                        onSubmit={(newImage) => {
                            const newGalleries = [...inputState.galleries]
                            newGalleries[editImage.index] = newImage
                            setInputState({ name: 'galleries', type: 'SET_ITEM', value: newGalleries })
                        }}
                    />
                    : null
            }

            <ImagePreview
                image={previewImage}
                onClose={() => setPreviewImage('')}
            />
        </Root>
    );
}

export default UnitDetailForm;