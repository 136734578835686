import React, { useContext, useState } from 'react'
import { generateStyle } from '../../_utils/DefaultStyle';
import { Button, IconButton, Theme, Typography } from '@mui/material';
import LoadingScreen from '../../_components/LoadingScreen';
import { PermissionContext } from '../../_contexts/PermissionContext';
import AddIcon from '@mui/icons-material/Add';
import DefaultAxios from '../../_utils/DefaultAxios';
import DataTable from '../../_components/_dataTable/DataTable';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { renderWarningButton } from '../../_utils/Helper';


const useStyles = generateStyle((theme: Theme) => ({
    root: {
        display: 'inline-flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },

}), "ArtcileLandingPage"
);

const ArticleLandingPage = () => {
    const permissions = useContext(PermissionContext)
    const navigate = useNavigate()
    const { Root, classes } = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const handleDelete = (id: number) => {
        setIsLoading(true)
        if (!permissions['article-landing-page.manage']) {
            Swal.fire({
                title: 'Akses Ditolak',
                text: 'Anda tidak memiliki izin untuk menghapus artikel ini.',
                icon: 'error',
            });
            return;
        }
        renderWarningButton('Apakah anda yakin ingin menghapus article landing page ini?').then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/article-landing-page/${id}`);
                    Swal.fire({
                        title: 'Terhapus!',
                        text: 'Artikel telah berhasil dihapus.',
                        icon: 'success'
                    });
                    setReDrawDatatable(new Date().getTime());
                } catch (error) {
                    Swal.fire({
                        title: 'Kesalahan',
                        text: 'Terjadi kesalahan saat menghapus artikel.',
                        icon: 'error',
                    });
                }
            }
        });
        setIsLoading(false)
    };

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            {
                !!permissions['article-landing-page.manage'] &&
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.addButton}
                    startIcon={<AddIcon />}
                    onClick={() => navigate('/article-landing-page/add')}
                    style={{ marginBottom: 20 }}

                >
                    Add Artcile
                </Button>
            }
            <DataTable
                url={`${process.env.REACT_APP_API_URL}/article-landing-page`}
                reDraw={reDrawDatatable}
                columns={[
                    {
                        name: 'is_sell',
                        label: 'Sell Page',
                        filterable: true,
                        defaultOption: '',
                        options: [
                            { key: '', value: 'All' },
                            { key: '1', value: 'Yes' },
                            { key: '0', value: 'No' },
                        ],
                        render: (data: any, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                            return data !== null ? Number(data) === 1 ? 'Yes' : 'No' : '-';
                        }
                    },
                    {
                        name: 'data_type_label',
                        label: 'Data Type',
                        filterable: true,
                        defaultOption: '',
                        options: [
                            { key: '', value: 'All' },
                            { key: 'area', value: 'Area' },
                            { key: 'district', value: 'District' },
                            { key: 'apartment', value: 'Apartment' },
                            { key: 'landmark', value: 'Landmark' }
                        ],
                        render: (data: any, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                            return data
                        }
                    },
                    {
                        name: 'type_label',
                        label: 'Type',
                        filterable: true,
                        defaultOption: '',
                        options: [
                            { key: '', value: 'All' },
                            { key: 'yearly', value: 'Yearly' },
                            { key: 'monthly', value: 'Monthly' },
                            { key: 'studio', value: 'Studio' },
                            { key: '1br', value: '1 Bedroom' },
                            { key: '2br', value: '2 Bedroom' },
                            { key: '3br', value: '3 Bedroom' },
                            { key: 'furnished', value: 'Furnished' },
                            { key: 'unfurnished', value: 'Unfurnished' }
                        ],
                        render: (data: any, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                            return data || '-';
                        }
                    },
                    {
                        name: 'data_name',
                        label: 'Name',
                        filterable: true,
                        render: (data: string, row: any) => {
                            return (
                                <div>
                                    <Typography style={{ fontSize: 14 }}>
                                        {row.data_name}
                                    </Typography>
                                </div>
                            )
                        }
                    },
                    {
                        name: 'landmark_name',
                        label: 'Landmark',
                        filterable: true,
                        render: (data: string, row: any) => {
                            return (
                                <div>
                                    <Typography style={{ fontSize: 14 }}>
                                        {row.landmark_name || '-'}
                                    </Typography>
                                </div>
                            )
                        }
                    },
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        render: (data: any, row: any) => {
                            return (
                                <>
                                    <IconButton aria-label="delete" color="primary" onClick={() => navigate(`/article-landing-page/edit/${data.id}`)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(data.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                            )
                        }
                    }
                ]}
            />

        </Root>
    )
}

export default ArticleLandingPage